<router>
{
    name: 'User Management',
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col
            UserManagement(:customerId='customerId')

</template>
<script>

import UserManagement from '@/components/portal/userManagement';

export default {
    meta: {
        role: 'admin'
    },
    components: {
        UserManagement
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {
        customerId () {
            return this.$store.state.customerId;
        }
    },
    mounted () {
        window.portalusers = this;
    }
};
</script>

<style scoped>
</style>
