
<template lang="pug">
.userManagement
    v-container
        v-row
            v-col.d-flex
                span.text-h5.brand--text User Management
                v-spacer
                v-btn(outlined, rounded, color='brand', @click='addNewUser') Add New User
                    v-icon(right) add
                v-menu(offset-y, max-height='400', :close-on-content-click='false')
                    template(v-slot:activator='{on, attrs}')
                        v-btn.ml-2(v-if='allowUserListEdit', v-on='on', outlined, rounded, color='brand', title='Add a user that already has a portal account') Add Existing User
                            v-icon add
                    v-card(flat, light)
                        v-card-text
                            v-text-field(rounded, outlined, label='Search', clearable, hide-details, dense, append-icon='search', color='brand', v-model='searchText')
                            v-virtual-scroll(height='300', item-height='50', :items='filteredUsers')
                                template(v-slot:default='{item}')
                                    v-list(light, dense)
                                        v-list-item(@click='addUserToCustomer(item)', :key='item.id')
                                            v-list-item-content
                                                v-list-item-title {{ `${item.firstName} ${item.lastName}` }}
                                        v-list-item(v-if='filteredUsers.length === 0')
                                            v-list-item-content
                                                v-list-item-title No users available to add
        v-row
            v-col.d-flex.flex-wrap
                v-card.mr-2.mb-2(v-for='(user, index) in users', :key='user.id', height='100', width='250', light, @click='editUser(user)')
                    v-card-text
                        v-row(align='center')
                            v-col(cols='4')
                                v-avatar.white--text(:style='{backgroundColor: stringToHslColor(user)}', size='64') {{ getInitials(user) }}
                            v-col.userCard(cols='8')
                                span.font-weight-bold {{ `${user.firstName} ${user.lastName}` }}
                                br
                                span {{ user.username }}
                                br
                                span {{ user.email }}
                                //- p todo: add title
        v-overlay(absolute, :value='busy')
            .text-h6 Please Wait...
            v-progress-linear(indeterminate)

    v-dialog(v-model='editUserDialog', max-width='750')
        v-card(light)
            v-card-title Edit User
            v-card-text
                UserEditor(:username='userToEdit.username', @userUpdated='handleUserUpdated', :customerId='customerId', allowRoleEdit)
                    template(v-slot:card-actions)
                        v-btn.mr-2.white--text(color='red', @click='disassociateUser(userToEdit.id)') DISASSOCIATE FROM CUSTOMER
    v-dialog(v-model='addUserDialog', max-width='750')
        v-card(light)
            v-card-title Add User
            v-card-text
                v-form(ref='newUserForm')
                    v-row
                        v-col
                            v-text-field(label='Username', outlined, dense, color='brand', v-model='newUser.username', :rules='stringRules', :error-messages='!newUserValidation.usernameIsUnique ? ["Username is already in use"]:[]', required)
                    v-row
                        v-col
                            v-text-field(label='First Name', outlined, dense, color='brand', v-model='newUser.firstName', :rules='stringRules', required)
                        v-col
                            v-text-field(label='Last Name', outlined, dense, color='brand', v-model='newUser.lastName', :rules='stringRules', required)
                    v-row
                        v-col
                            v-text-field(label='Email', outlined, dense, color='brand', v-model='newUser.email', :rules='stringRules', :error-messages='!newUserValidation.emailIsUnique ? ["Email is already in use"]:[]', required)
                        v-col
                            v-text-field(label='Phone Number', outlined, dense, color='brand', v-model='newUser.phoneNumber', :rules='phoneRules')
                    v-row
                        v-col
                            v-text-field(label='Password', outlined, dense, color='brand', v-model='newUser.password', :rules='passwordRules', type='password', required)
                    v-card-actions
                        v-spacer
                        v-btn(text, @click='addUserDialog = false') Cancel
                        v-btn.white--text(@click='saveNewUser', color='brand') Save

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import {cloneDeep} from 'lodash';
import dti from 'dti';
import User from '@/components/devops/user';
import UserEditor from '@/components/portal/usereditor.vue';

export default {
    components: {
        User,
        UserEditor
    },
    props: {
        customerId: {
            type: Number,
            default () {
                return -1;
            }
        },
        allowUserListEdit: {
            type: Boolean,
            default () {
                return false;
            }
        }
    },
    data () {
        return {
            dti,
            validForm: false,
            message: '',
            allUsers: [],
            user: {},
            customer: {customersConfig:{}, customersUsers: []},
            defaultUser: {
                id: -1, firstName: '', lastName: '', username:'', email:'', password: '', phoneNumber: '', customerId: -1, addedBy: 'customer'
            },

            stringRules: [
                value => !!value || 'Required'
            ],
            phoneRules: [
                value => !!value || 'Required',
                value => (!!value && /^[0-9]*$/.test(value)) || 'Only numbers',
                value => (!!value && value.length === 10) || '10 digit phone'
            ],
            passwordRules: [
                value => !!value || 'Required',
                value => !value || /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&£*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,}$/.test(value) || 'Invalid Password.'
            ],
            editUserDialog: false,
            addUserDialog: false,
            savingNewUser: false,
            userToEdit: {},
            newUser: {
                firstName: '',
                lastName: '',
                username: '',
                password: '',
                email: '',
                phoneNumber: '',
                id: -1,
                addedBy: 'customer',
                customerId: this.customerId
            },
            newUserValidation: {
                usernameIsUnique: true,
                emailIsUnique: true
            },
            searchText: null
        };
    },
    methods: {
        async saveNewUser () {
            const valid = this.$refs.newUserForm.validate();
            if (!valid) {
                return;
            }
            this.newUserValidation.usernameIsUnique = true;
            this.newUserValidation.emailIsUnique = true;
            this.savingNewUser = true;
            const userToAdd = cloneDeep(this.newUser);
            userToAdd.customerId = this.customer.id;
            const result = await this.post('saveUsers', {
                users: [userToAdd]
            });
            if (result) {
                if (result.err && result.err.name === 'SequelizeUniqueConstraintError') {
                    for (const field of Object.keys(result.err.fields)) {
                        if (field === 'customers_users_email') {
                            this.newUserValidation.emailIsUnique = false;
                        }
                        if (field === 'customers_users_username') {
                            this.newUserValidation.usernameIsUnique = false;
                        }
                    }
                    return;
                }
                const newUser = result.users[0];
                this.customer.customersUsers.push(newUser);
                this.$emit('newUserAdded', newUser);
                this.savingNewUser = false;
                this.addUserDialog = false;
            } else {
                this.message = 'An unknown error occurred!';
            }
        },

        async getCustomerData (id=this.customerId) {
            this.getAllUsers();
            const result = await this.post('getCustomer', {id});
            this.customer = result.customer;
        },

        addNewUser () {
            this.addUserDialog = true;
        },

        editUser (user) {
            this.userToEdit = user;
            this.editUserDialog = true;
        },

        handleUserUpdated () {
            this.editUserDialog = false;
            this.getCustomerData();
        },

        getInitials (user) {
            return `${user.firstName[0]}${user.lastName[0]}`;
        },
        stringToHslColor (user) {
            const s = 30;
            const l = 50;
            const fullName = `${user.lastName} ${user.firstName}`;
            let hash = 0;
            for (let i = 0; i < fullName.length; i++) {
                hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
            }

            const h = hash % 360;
            const color = `hsl(${h}, ${s}%, ${l}%)`;
            return color;
        },
        /**
         * Requests user data from the server
         */
        getAllUsers () {
            if (this.allowUserListEdit) {
                this.socketEmit('getAllUsers', null, (results) => {
                    this.allUsers = results;
                });
            }
        },
        addUserToCustomer (existingUser) {
            const user = cloneDeep(existingUser);
            user.customerId = this.customer.id;
            user.addedBy = 'dorsett';
            this.customer.customersUsers.push(user);
            this.$emit('existingUserAdded', user);
        },
        disassociateUser (userId) {
            this.$emit('disassociateUser', userId);
            this.$nextTick(() => {
                const itemToRemove = this.customer.customersUsers.find(item => item.id === userId);
                const index = this.customer.customersUsers.indexOf(itemToRemove);
                if (index > -1) {
                    this.customer.customersUsers.splice(index, 1);
                }
                this.editUserDialog = false;
            });
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },

        users () {
            return this.customer?.customersUsers || [];
        },
        canEditUsers () {
            // todo: only let specific people/roles edit users.
            return true;
        },
        /**
         * Calculates the list of users that are _not_ linked to the customer being edited.
         */
        unassociatedUsers () {
            const users = [];
            for (const user of this.allUsers) {
                const userExists = this.users.find(u => u.id === user.id);
                if (!userExists) {
                    users.push(user);
                }
            }
            return users.sort((a, b) => {
                return a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName);
            });
        },
        filteredUsers () {
            if (this.searchText) {
                const searchString = this.searchText.trim().toLowerCase();
                const filteredUsers = this.unassociatedUsers.filter(user => {
                    const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
                    return fullName.match(searchString);
                });
                return filteredUsers;
            }
            return this.unassociatedUsers;
        }
    },
    watch: {
        customerId () {
            this.getCustomerData();
        },
        addUserDialog () {
            if (this.$refs.newUserForm) {
                this.$refs.newUserForm.reset();
            }
        }
    },
    mounted () {
        this.getCustomerData();
        this.user = this.$store.state.fullUser;
        window.portalusermanagement = this;
    },
};
</script>
<style>
.userCard {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>
