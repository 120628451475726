<template lang="pug">
.portalUser
    v-list-item(two-line)
        v-list-item-icon
            v-avatar.mt-3(color='blue', size='36')
                span.white--text {{ avatarText }}
        v-list-item-content
            v-list-item-title {{ displayName }}
            v-list-item-subtitle {{ email }}
        v-list-item-action(v-if='showResetPasswordButton')
            v-btn(icon, color='blue', @click='showResetPasswordDialog', title='Reset Password')
                v-icon restore
        v-list-item-action(v-if='showDelete')
            v-btn(icon, color='red', @click='showDeleteDialog')
                v-icon delete
        v-list-item-action(v-if='showRemoveAssociation')
            v-btn(icon, @click='removeAssociation', title='Remove user')
                v-icon remove
    v-dialog(v-model='deleteDialog', max-width='500px')
        v-card
            v-card-title Delete User
            v-card-text
                p Are you sure you want to delete this user? This action cannot be undone.
                p They are currently associated with the following customers:
                p {{ associatedCustomers.join(', ') }}
            v-card-actions
                v-spacer
                v-btn(text, @click='showDeleteDialog = false') Cancel
                v-btn(color='red', @click='deleteUser') Delete
    v-dialog(v-model='resetPasswordDialog', max-width='500px')
        v-form(ref='form', v-model='validForm')
            v-card(light)
                v-card-title Reset Password
                v-card-text
                    p {{ `Enter the new password for ${firstName} ${lastName}` }}
                    v-text-field(type='password', v-model='newPassword', color='brand', outlined, :rules='passwordRules')
                v-card-actions
                    v-spacer
                    v-btn(text, @click='resetPasswordDialog = false') Cancel
                    v-btn.white--text(color='blue', @click='resetPassword', :disabled='!validForm') Reset

</template>
<script>
export default {
    props: {
        firstName: {
            type: String,
            default () {
                return 'John';
            }
        },
        lastName: {
            type: String,
            default () {
                return 'Smith';
            }
        },
        email: {
            type: String,
            default () {
                return 'ftrivette@dorsettcontrols.com';
            }
        },
        showDelete: {
            type: Boolean,
            default () {
                return false;
            }
        },
        showRemoveAssociation: {
            type: Boolean,
            default () {
                return false;
            }
        },
        customers: {
            type: Array,
            default () {
                return [];
            }
        },
        id: {
            type: Number,
            default () {
                return -1;
            }
        }
    },
    data () {
        return {
            deleteDialog: false,
            resetPasswordDialog: false,
            newPassword: '',
            passwordRules: [
                value => !value || /^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&£*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,}$/.test(value) || 'Invalid Password.',
                value => Boolean(value) || 'Password cannot be blank'
            ],
            validForm: false
        };
    },
    computed: {
        displayName () {
            return `${this.firstName} ${this.lastName}`;
        },
        avatarText () {
            return `${this.firstName[0]}${this.lastName[0]}`;
        },
        associatedCustomers () {
            const customers = this.customers.map(c => c.name);
            return customers;
        },
        showResetPasswordButton () {
            return true;
        }
    },
    methods: {
        showDeleteDialog () {
            this.deleteDialog = true;
        },
        async deleteUser () {
            await this.$emit('deleteUser', this.id);
            this.deleteDialog = false;
        },
        removeAssociation () {
            this.$emit('removeAssociation', this.id);
        },
        showResetPasswordDialog () {
            this.resetPasswordDialog = true;
        },
        async resetPassword () {
            await this.$emit('resetPassword', {
                userId: this.id,
                password: this.newPassword
            });
            this.resetPasswordDialog = false;
            this.$refs.form.reset();
        }
    }
};
</script>
